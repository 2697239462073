import { useField } from 'formik';
import dropDown from '../../assets/drop-down.svg';
import { useEffect } from 'react';

type SelectFieldProp = {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  valueField?: string;
  labelField?: string;
  keyField: string;
  options: Record<string, any>[];
  groupClassName?: string;
  onChange?: (value: string) => void;
};

function SelectField({
  label,
  className,
  disabled,
  loading,
  valueField = 'value',
  labelField = 'label',
  keyField,
  options,
  groupClassName,
  onChange,
  ...props
}: Readonly<SelectFieldProp>) {
  const [field, meta] = useField(props);

  useEffect(() => {
    if (options.length === 1) {
      field.onChange(options[0].value);
    }
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(field?.value);
    }
  }, [field?.value]);

  return (
    <div className="input-holder-holder">
      <div className="input-holder">
        <select
          style={{ textTransform: 'capitalize' }}
          disabled={disabled}
          className="form-select"
          // aria-label={translate('lender-web-app-floating-label-select')}
          {...field}
          {...props}
          id={field?.name}
          // onChange={(e) => onChange ? onChange(e.target.value) : ''}
        >
          <option key={-1} value=""></option>
          {options?.map((option) => (
            <option key={option[keyField]} value={option[valueField]}>
              {option[labelField] ?? option[valueField]}
            </option>
          ))}
        </select>
        <label
          htmlFor={field?.name}
          className={`input-label ${field.value && 'top-label'}`}
        >
          {label}
        </label>
      </div>

      <img src={dropDown} alt="down arrow" className="dropdown" />

      {meta.error && meta.touched && <p className="error">{meta.error}</p>}
    </div>
  );
}

export { SelectField };
