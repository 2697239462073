/* eslint-disable no-prototype-builtins */
import create from 'zustand';
import { toast } from 'react-hot-toast';
import { axiosInstance } from './utils/axios';
import { persist } from 'zustand/middleware';
import {
  getLoanDetails,
  getLoanOptions,
  postVerifyBVN,
  createAccount,
  addAccount,
  postRefreshToken,
  postLoanEligibility,
  postUploadPhoto,
  addPhoto,
  getPreferences,
  postInitCard,
  getAllUserCards,
  createPin,
  postCreateLoan,
  getUserGuarantors,
  getUserDocuments,
  getDocumentTypes,
  addUserDocument,
  postCheckLoanEligibility,
  // modifyUserDocument,
  postUploadFile,
  getWallet,
  certeLogEvent,
  getAllStates,
  postLogin,
  themeDetails,
  postResetPasswordOtp,
  postResetPassword,
  modifyUserDocument,
  userProfile,
  checkUserOnOrg,
  getUserLoanDetails,
  getAllLGAs,
  postLoanBreakdown,
  postVerifyBankAccount,
  postGenerateGuarantorLink,
  getAllBanks,
  handleMandates,
  paymentInit,
  paymentComplete,
} from './utils/endpoints';
import moment from 'moment';
import ShortUniqueId from 'short-unique-id';
import { apiRequest } from './utils/api-request';
import { getLocation, getMissingRequiredDocument } from './utils';
import {
  AddAccount,
  AddPhoto,
  BankInfo,
  BorrowerInfo,
  CardsInitDataInterface,
  CreateBVN,
  CustomErrorResponse,
  DocumentTypes,
  DocumentsInterface,
  GuarantorsInterface,
  InvestorStoreInterface,
  LGAInterface,
  LoanOffersResponse,
  RefreshToken,
  SelectedLoanInterface,
  StatesInterface,
  UploadedDocumentsInterface,
  UserCardsResponse,
  VerifyBVN,
} from './utils/interfaces';
import axios, { AxiosError } from 'axios';
import { RefObject } from 'react';
import { GeoLocationData, LoginProps } from './components';
import { translate } from '@lendsqr/lingua-react';
import { fullDateTimeFormat, getFeeAmount } from './utils/formatter';
import {
  osName,
  osVersion,
  deviceType,
  browserName,
  browserVersion,
  mobileVendor,
  mobileModel,
} from 'react-device-detect';
import { parseTypes } from './utils/contexts';
import { ScreenState } from './components/bnpl/bnpl';

export const screens = {
  DETAILS: 'DETAILS',
  PERSONAL: 'PERSONAL',
  EDUCATION: 'EDUCATION',
  BVN: 'BVN',
  VERIFY_BVN: 'VERIFY_BVN',
  BANK: 'BANK',
  INELIGIBLE: 'INELIGIBLE',
  REPAYLOAN: 'REPAYLOAN',
  UPLOAD: 'UPLOAD',
  OFFERS: 'OFFERS',
  BREAKDOWN: 'BREAKDOWN',
  WALLET: 'WALLET',
  PASSWORD: 'PASSWORD',
  SUCCESS: 'SUCCESS',
  GUARANTOR: 'GUARANTOR',
  FILE_UPLOAD: 'FILE_UPLOAD',
  DOCUMENTS: 'DOCUMENTS',
  ADDITIONAL_DATA: 'ADDITIONAL_DATA',
  LOGIN: 'LOGIN',
  FORGOTPASSWORD: 'FORGOTPASSWORD',
  OTP: 'OTP',
  RESETPASSWORD: 'RESETPASSWORD',
};

const defaultState = {
  status: 'loading',
  userDocStatus: 'loading',
  docStatus: 'loading',
  loading: false,
  screen: screens.DETAILS,
  bnpl_screen: ScreenState.OFFER_SELECTION,
  loan_data: {},
  ineligible: false,
  clf_page: 0,
  attributes: '{}',
  loan_options: [],
  preferences: {},
  uploadedDocuments: [],
  documents: [],
  documentTypes: [],
  cards: [],
  loan: {},
  user: {},
  guarantors: [],
  organization: {},
  additional_loan_data: null,
  token: null,
  phone_number: null,
  longitude: null,
  latitude: null,
  missingDoc: null,
  user_log_id: null,
  user_certe_guid: null,
  lsq_ref: null,
  runningLoan: null,
  auth_token: null,
  activateCerte: true,
  email: null,
  otp: null,
  last_five_digits: '',
  language: 'en',
  languageName: 'English',
  languageFlag: '',
  locale: 'en-US',
  currency: '',
  country_code: 'ng',
  stateOptions: [],
  states: [],
  lgas: [],
  logged_in: '',
  prevPage: '',
  lgaLoader: false,
  bnpl_values: {},
  user_activated: false,
  mandates: null,
  loginToken: '',
  selectedMandate: null,
  selectedCard: null,
  schedules: [],
  fees: [],
  loanOffer: null,
  code: '',
  isBuyNow: false,
  bnpl_conf: {},
  loanType: 'pay_later',
  wallet: {},
  guarantorLink: '',
  equityContribution: 0,
  history: [],
};

const currentTime = Date.now();

const InvestorStore = (set: any, get: any) => ({
  ...defaultState,
  setScreen: (screen: string) => {
    set({ screen });
  },
  setBNPLScreen: (bnpl_screen: string) => {
    set({ bnpl_screen });
  },
  setLoading: (loading = true) => {
    set({ loading });
  },
  setLgaLoader: (lgaLoader: boolean) => {
    set({ lgaLoader });
  },
  setToken: (token: string) => {
    set({ token });
  },
  setCLFAttribute: (attributes: string) => {
    set({ attributes: attributes });
  },
  setCLFPage: (page: number) => {
    set({ clf_page: page });
  },
  setUploadedDocuments: (document: UploadedDocumentsInterface) => {
    const { uploadedDocuments } = get();

    const updatedDocuments = uploadedDocuments.filter(
      (item: UploadedDocumentsInterface) => item.id !== document.id
    );

    set({ uploadedDocuments: [...updatedDocuments, document] });
  },
  setLanguage: (
    code: string,
    name: string,
    flag: string,
    countryCode: string,
    locale: string
  ) => {
    set({
      language: code,
      languageName: name,
      languageFlag: flag,
      country_code: countryCode,
      locale: locale,
    });
  },
  setLgas: (lgas: { label: string; value: string }[]) => {
    set({ lgas: lgas });
  },
  getRefCode: (state: string) => {
    const [letSee]: StatesInterface[] = get().states.filter(
      (st: StatesInterface) => st.code_description == state
    );
    return letSee?.ref_code;
  },
  getLgas: async (getState: string) => {
    const { setLgaLoader, getRefCode, setLgas, locale } = get();

    if (getState === null || getState === undefined || getState === '') {
      return;
    } else {
      try {
        setLgaLoader(true);
        const { data } = await axiosInstance.get(
          getAllLGAs(getRefCode(getState)),
          {
            headers: {
              'x-locale': `${locale}`,
            },
          }
        );
        const lgasList = data.data.map((item: LGAInterface) => {
          return {
            label: item.code_description,
            value: item.code_description,
          };
        });

        setLgas(lgasList);
        setLgaLoader(false);
      } catch (error) {
        console.error(error);
      }
    }
  },
  getOptionsByKey: (key: string) => {
    const options = get().loan_options;
    const group = options.find((o: { key: string }) => o.key === key);

    if (group) {
      return group.option;
    }
    return [];
  },
  logEvent: (event: { name: string; description: string }) => {
    const { user_log_id, loan_data, activateCerte, lsq_ref } = get();

    const EventLog = {
      user_id: user_log_id,
      org_id: loan_data.organization.id,
      app_name: 'Loan Origination and Invitations Web App',
      session_id: `${loan_data.reference}` || user_log_id,
      event_name: event.name,
      description: event.description,
      time: fullDateTimeFormat(currentTime ?? new Date()),
    };

    if (activateCerte) {
      axiosInstance.post(certeLogEvent, EventLog, {
        headers: { ref: lsq_ref },
      });
    }
  },
  checkUser: async () => {
    const { locale, token, loan, bnpl_values } = get();

    set({
      loading: true,
    });

    let user_number;

    if (loan?.phone_number) {
      user_number = `${loan?.phone_number?.replace('234', '0')}`;
    } else {
      user_number = bnpl_values?.phone_number.replace('+234', '0');
    }

    await axiosInstance
      .get(checkUserOnOrg(user_number), {
        headers: {
          authorization: `Bearer ${token}`,
          'x-locale': `${locale}`,
        },
      })
      .then((response) => {
        set({
          loading: false,
          screen: 'LOGIN',
          user_activated: response?.data?.data?.activated,
          bnpl_screen: ScreenState.ACCOUNT_INFORMATION,
        });

        get().next(
          ScreenState.OFFER_SELECTION,
          ScreenState.ACCOUNT_INFORMATION
        );
      })
      .catch((err) => {
        if (
          err.response.data.message === 'User not found' &&
          err.response.status === 404
        ) {
          set({
            loading: false,
            screen: 'PERSONAL',
            user_activated: false,
            // bnpl_screen: ScreenState.ACCOUNT_INFORMATION,
            user_profile_details: {},
            logged_in: false,
          });

          get().next(
            ScreenState.OFFER_SELECTION,
            ScreenState.ACCOUNT_INFORMATION
          );
        } else {
          set({
            loading: false,
          });
        }
      });
  },
  next: (from: string, to: string) => {
    const { history } = get();

    const tempHistory = [...history];
    tempHistory.push(from);

    set({ bnpl_screen: to, history: [...tempHistory] });
  },
  back: () => {
    const { history } = get();

    const tempHistory = [...history];

    const previousPage = tempHistory.pop();

    set({ bnpl_screen: previousPage, history: [...tempHistory] });
  },
  setLoan: (data: BorrowerInfo) => {
    const { loan } = get();

    set({ loan: { ...loan, ...data } });
  },
  setLocation: async () => {
    const newLocation = (await getLocation()) as GeoLocationData;

    set({
      latitude: newLocation.latitude,
      longitude: newLocation.longitude,
    });

    return {
      latitude: newLocation.latitude,
      longitude: newLocation.longitude,
    };
  },
  getLoanDetails: async (token: string, locale = '') => {
    const { user_log_id, user_certe_guid, lsq_ref, loan } = get();

    if (locale === '') {
      set({ status: 'loading' });
    }

    try {
      const uid = new ShortUniqueId({
        dictionary: 'number',
      });

      const guid = new ShortUniqueId({
        dictionary: 'alphanum_lower',
      });

      if (lsq_ref === null) {
        const formattedId = `LSQREF-${guid.randomUUID(5)}-${guid.randomUUID(
          5
        )}-${guid.randomUUID(5)}-${guid.randomUUID(5)}-${guid.randomUUID(5)}`;

        set({
          lsq_ref: formattedId,
        });
      }

      if (Object.keys(loan).length !== undefined) {
        const { data } = await axiosInstance.get(getLoanDetails, {
          headers: {
            authorization: `Bearer ${token}`,
            'x-locale': `${locale}`,
          },
        });

        try {
          const bnpl_conf = JSON.parse(
            data.data.product.attributes['bnpl-configurations']
          );

          if (
            !bnpl_conf?.bnpl?.pay_later?.enabled &&
            bnpl_conf?.bnpl?.pay_now.enabled
          ) {
            set({ loanType: 'pay_now' });
          } else {
            set({ loanType: 'pay_later' });
          }

          set({ bnpl_conf });
        } catch (error) {
          set({ bnpl_conf: {} });
        }

        set({
          status: 'success',
          loan_data: data.data,
          additional_loan_data:
            data.data.product?.attributes?.additional_loan_data,
          organization: data.data.organization || {},
          currency: data.data.organization.currency,
        });

        const response = await axiosInstance.get(getLoanOptions, {
          headers: {
            authorization: `Bearer ${token}`,
            'x-locale': `${locale}`,
          },
        });

        const domainData = await axiosInstance.get(
          themeDetails(data.data.organization.slug)
        );

        parseTypes(domainData.data.data.theme.options);

        const statesRes = await axiosInstance.get(
          getAllStates(data.data.organization.country),
          {
            headers: { 'x-locale': `${locale}` },
          }
        );

        const statesList = statesRes.data.data.map((item: StatesInterface) => {
          return {
            label: item.code_description,
            value: item.code_description,
          };
        });

        set({
          loan_options: response.data.data,
          stateOptions: statesList,
          states: statesRes.data.data,
          auth_token: domainData.data.data.auth.token,
          token,
        });

        if (data.data.product_id) {
          set({ loan: { ...get().loan, product_id: data.data.product_id } });
        }
      } else {
        set({
          status: 'success',
        });
      }

      if (user_log_id === null && user_certe_guid === null) {
        set({ user_log_id: Number(uid.randomUUID(5)) });
        set({ user_certe_guid: guid.randomUUID(10) });
      }
    } catch (error) {
      window.location.href = `/404`;
      set({
        status: 'failed',
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>).catch(
        (error) => console.error(error)
      );
    }
  },
  updateLoanDetails: async (status = 'accepted') => {
    const { token, locale } = get();
    try {
      await axiosInstance.put(
        getLoanDetails,
        {
          status,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            'x-locale': `${locale}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  },

  setBNPLValues(values: any) {
    const { bnpl_values } = get();

    set({ bnpl_values: { ...bnpl_values, ...values } });
  },

  setValues(values: any) {
    set({ ...values });
  },

  verifyBVN: async ({ bvn, email }: VerifyBVN, visualInput: string) => {
    const { locale } = get();

    const translatedMessage = await translate(
      'invitation-web-app-bvn-check-error'
    );

    set({ loading: true });
    try {
      const { data } = await axiosInstance.post(
        postVerifyBVN,
        { bvn },
        {
          headers: { 'x-locale': `${locale}` },
        }
      );

      if (data.data.mobile === null) {
        throw new Error(await translate('invitation-web-app-bvn-check-error'));
      } else {
        set({
          bvn_phone_number: data.data.mobile,
          dob: inputSlashDates(data.data?.formatted_dob || data.data.dob),
          bvn,
          loading: false,
          phone_number: visualInput,
          email,
        });
      }
    } catch (error) {
      set({
        loading: false,
      });

      if (
        error &&
        typeof error === 'object' &&
        'message' in error &&
        typeof error.message === 'string'
      ) {
        if (error.message.includes('400')) {
          const customError = {
            message: translatedMessage,
          };
          await handleAxiosError(
            customError as AxiosError<CustomErrorResponse>
          ).catch((error) => console.error(error));
        }
      }
      await handleAxiosError(error as AxiosError<CustomErrorResponse>).catch(
        (error) => console.error(error)
      );
    }
  },
  addAccount: async (values: AddAccount) => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    set({ loading: true });
    try {
      await apiRequest(addAccount, 'post', xLocale, values);
      set({
        loading: false,
      });
    } catch (error) {
      set({
        loading: false,
      });
      if (error === 'You have a pending loan application') {
        return Promise.reject(error);
      }
      await handleAxiosError(error as AxiosError<CustomErrorResponse>).catch(
        (error) => console.error(error)
      );
    }
  },
  createBVN: async (
    data: CreateBVN = { phone: '', dob: '' },
    stage?: string
  ) => {
    set({ loading: true });

    const {
      loan_data,
      bvn,
      refreshToken,
      phone_number: temp_phone,
      email: temp_email,
      setScreen,
      bvn_phone_number,
      token,
      locale,
      setBNPLScreen,
    } = get();

    set({
      entered_dob: data?.dob,
      last_five_digits: data?.phone,
    });

    const translatedMessage = await translate(
      'invitation-web-app-bvn-validity-check-error'
    );

    const bvn_dob = get().dob;
    try {
      if (get().screen === 'VERIFY_BVN' || stage === 'verify') {
        if (
          bvn_phone_number.slice(bvn_phone_number.length - 5) !== data?.phone ||
          moment(data?.dob).format('MM/DD/YYYY') !== bvn_dob
        ) {
          throw Error(translatedMessage);
        }
      }

      const store = get();

      await axiosInstance
        .post(
          createAccount,
          {
            bvn,
            phone_number: `0${temp_phone.replace('+234', '')}`,
            email: loan_data.email || temp_email,
            bvn_phone_number,
            dob: data?.dob || get().dob,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              'x-locale': `${locale}`,
            },
          }
        )
        .then((response) => {
          set({
            user: {
              ...response.data.data,
              sessionTimeoutId: setInterval(() => {
                refreshToken();
              }, response.data.data.expires_in * 1000),
            },
            loginToken: response.data.data.token,
          });
        })
        .then(() => store.fetchDocs())
        .then(() => {
          set({ history: [ScreenState.OFFER_SELECTION] });

          if (!get().user.photo_url) {
            set({
              loading: false,
            });
            setScreen(screens.UPLOAD);

            if (get().bnpl_conf?.selfie_validation?.enabled) {
              setBNPLScreen(ScreenState.SELFIE_CHECK);
            } else {
              setBNPLScreen(ScreenState.ORDER_INFORMATION);
            }
          } else if (
            get().documents?.length < get().documentTypes.length ||
            (get().documents?.length >= get().documentTypes.length &&
              get().user.photo_url)
          ) {
            store.setLocation();
            store.preSubmitForm();
          }
        });
    } catch (error: any) {
      set({
        loading: false,
      });
      if (error.isAxiosError) {
        if (error.response.data.message.includes('phone number')) {
          set({
            screen: screens.LOGIN,
            logged_in: 'wrong number',
            prevPage: 'VERIFY_BVN',
          });
        } else {
          await handleAxiosError(
            error as AxiosError<CustomErrorResponse>
          ).catch((error) => console.error(error));
        }
      } else {
        await handleAxiosError(error as AxiosError<CustomErrorResponse>).catch(
          (error) => console.error(error)
        );
      }
    }
  },
  straightToBank: async () => {
    const store = get();
    set({ loading: true });

    await store.fetchDocs().then(() => {
      if (!get().user_profile_details.photo_url) {
        set({
          loading: false,
        });
        store.setScreen(screens.UPLOAD);
      } else if (
        get().documents?.length < get().documentTypes.length ||
        (get().documents?.length >= get().documentTypes.length &&
          get().user_profile_details.photo_url)
      ) {
        store.setLocation();
        store.preSubmitForm();
      }
    });
  },
  refreshToken: async () => {
    const { locale, refresh_token } = get();
    const xLocale = {
      XLocale: locale,
    };
    const token = refresh_token;
    try {
      const response = (await apiRequest(postRefreshToken, 'post', xLocale, {
        token,
      })) as RefreshToken;
      set({ access_token: response.data.access_token });
    } catch (e) {
      console.dir(e);
    }
  },
  logout: () => {
    const { sessionTimeoutId } = get();
    clearInterval(sessionTimeoutId);
    set(defaultState);
    sessionStorage.clear();
    window.location.reload();
  },
  login: ({ phone_number, password, device_id }: LoginProps) => {
    const { auth_token, locale, user_certe_guid, logEvent } = get();

    const logEventDetails = {
      name: `Loan web SDK: login`,
      description: `User ${user_certe_guid} has logged in and gotten existing details for loan application`,
    };

    set({
      loading: true,
    });

    const { longitude, latitude } = get().setLocation();

    axiosInstance
      .post(
        postLogin,
        {
          phone_number,
          password,
          device_id,
          longitude: longitude,
          latitude: latitude,
          mobile_os: osName?.toLowerCase() ?? undefined,
          device_name:
            `${
              mobileVendor === 'none' ? '' : mobileVendor || ''
            } ${mobileModel}`.trim() ||
            `${deviceType || ''} ${browserName || ''} ${
              browserVersion || ''
            }`.trim() ||
            undefined,
          os_version: osVersion ?? undefined,
        },
        {
          headers: {
            authorization: `Basic ${auth_token}`,
            'x-locale': `${locale}`,
          },
        }
      )
      .then(async (data) => {
        if (get().prevPage !== 'VERIFY_BVN') {
          set({
            logged_in: 'success',
            loginToken: data.data.data.token ?? data.data.data.access_token,
            token: data.data.data.token ?? data.data.data.access_token,
          });
        }

        logEvent(logEventDetails);

        axiosInstance
          .get(userProfile, {
            headers: {
              authorization: `Bearer ${
                data.data.data.token ?? data.data.data.access_token
              }`,
              'x-locale': `${locale}`,
            },
          })
          .then((response) => {
            get().getLgas(response.data.data.state);

            set({
              user_profile_details: response.data.data,
              phone_number: phone_number,
            });

            // get().getWallet();

            if (
              !response.data.data.photo_url &&
              get().bnpl_conf?.selfie_validation?.enabled
            ) {
              set({ bnpl_screen: ScreenState.SELFIE_CHECK });
            } else {
              set({ bnpl_screen: ScreenState.ORDER_INFORMATION });
            }
          })
          .catch(async (e) => {
            set({
              loading: false,
            });

            await handleAxiosError(e as AxiosError<CustomErrorResponse>).catch(
              (error) => console.error(error)
            );
          });

        axiosInstance
          .get(getUserLoanDetails, {
            headers: {
              authorization: `Bearer ${
                data.data.data.token ?? data.data.data.access_token
              }`,
              'x-locale': `${locale}`,
            },
          })
          .then(async (response) => {
            set({
              user_profile_details: {
                ...get().user_profile_details,
                ...response.data.data[0].loan_profile,
              },
            });
          })
          .catch(async (e) => {
            if (e.response.data.message) {
              console.error(e.response.data.message);
            } else {
              set({
                loading: false,
              });

              await handleAxiosError(
                e as AxiosError<CustomErrorResponse>
              ).catch((error) => console.error(error));
            }
          });
      })

      .then(() => {
        // Timeout to let store update before moving to next page
        setTimeout(() => {
          if (get().prevPage === 'VERIFY_BVN') {
            get().createBVN();
          } else {
            set({
              loading: false,
              screen: 'PERSONAL',
            });
          }
        }, 3000);
      })
      .catch(async (e) => {
        set({
          loading: false,
        });

        await handleAxiosError(e as AxiosError<CustomErrorResponse>).catch(
          (error) => console.error(error)
        );
      });
  },
  sendOTP: async (phone = '') => {
    const { auth_token, phone_number, locale } = get();

    const user_phone = phone || phone_number;

    try {
      set({
        loading: true,
      });

      await axiosInstance
        .post(
          postResetPasswordOtp,
          {
            phone_number: user_phone,
          },
          {
            headers: {
              authorization: `Basic ${auth_token}`,
              'x-locale': `${locale}`,
            },
          }
        )
        .then(() => {
          set({
            loading: false,
            screen: screens.OTP,
            phone_number: phone || phone_number,
          });
        });
    } catch (e) {
      set({
        loading: false,
      });
      await handleAxiosError(e as AxiosError<CustomErrorResponse>).catch(
        (error) => console.error(error)
      );
    }
  },
  saveOTP: (otp: string) => {
    set({
      otp,
    });
  },
  resetPassword: async (new_password: string) => {
    const { phone_number, otp, logEvent, auth_token, user_certe_guid, locale } =
      get();

    set({
      loading: true,
    });

    const logEventDetails = {
      name: `Loan web SDK: Password Reset`,
      description: `User ${user_certe_guid} created new password for account.`,
    };

    await axiosInstance
      .post(
        postResetPassword,
        {
          new_password,
          otp,
          phone_number,
        },
        {
          headers: {
            authorization: `Basic ${auth_token}`,
            'x-locale': `${locale}`,
          },
        }
      )
      .then(async () => {
        logEvent(logEventDetails);

        set({
          loading: false,
          logged_in: '',
          screen: screens.LOGIN,
        });
      })
      .catch(async (e) => {
        set({
          loading: false,
        });

        await handleAxiosError(e as AxiosError<CustomErrorResponse>).catch(
          (error) => console.error(error)
        );
      });
  },
  uploadUserDoc: async (fileBlob: Blob, typeId: number, docId: number) => {
    const { locale } = get();

    const xLocale = {
      XLocale: locale,
    };

    const { user_certe_guid, logEvent, missingDoc } = get();

    set({ loading: true });

    const url =
      missingDoc.doc.userData?.status === 'Rejected'
        ? modifyUserDocument(docId)
        : addUserDocument;
    const method =
      missingDoc.doc.userData?.status === 'Rejected' ? 'put' : 'post';

    try {
      const formData = new FormData();
      if (fileBlob.type.includes('image')) {
        formData.append('file', fileBlob, 'user-document.png');
      } else {
        formData.append('file', fileBlob);
      }
      const uploadRes = async () => {
        if (fileBlob.type.includes('image')) {
          const contentType = {
            ContentType: 'multipart/form-data',
          };

          const res = await apiRequest(
            postUploadPhoto('irorun'),
            'post',
            { ...contentType, ...xLocale },
            formData
          );

          return res;
        } else {
          const contentType = {
            ContentType: 'multipart/form-data',
          };

          const res = await apiRequest(
            postUploadFile('irorun'),
            'post',
            { ...contentType, ...xLocale },
            formData
          );

          return res;
        }
      };

      const { data } = (await uploadRes()) as AddPhoto;

      console.log(data, 'data from upload', missingDoc);

      const response = await apiRequest(url, method, xLocale, {
        url: data.url,
        type_id: typeId,
      });

      console.log('2 api call', response);
      const modifiedString = missingDoc.instructionHeader?.replace(
        'Upload your ',
        ''
      );
      const logEventDetails = {
        name: `${'Loan web SDK: Document Upload'}`,
        description: `User ${user_certe_guid} has uploaded ${modifiedString}`,
      };

      logEvent(logEventDetails);

      set({
        loading: false,
      });

      get().preSubmitForm();
    } catch (error) {
      set({
        loading: false,
      });

      if (
        error &&
        typeof error === 'object' &&
        'message' in error &&
        typeof error.message === 'string'
      ) {
        if (error.message.includes('exists')) {
          const logEventDetails = {
            name: `Loan web SDK: Document Upload`,
            description: `User ${user_certe_guid} has been prevented from uploading a document that already exists`,
          };

          logEvent(logEventDetails);
          return get().setScreen(screens.BANK);
        } else {
          await handleAxiosError(
            error as AxiosError<CustomErrorResponse>
          ).catch((error) => console.error(error));
        }
      }
    }
  },
  generateGuarantorLink: async () => {
    const { locale } = get();
    set({ loading: true });

    const xLocale = {
      XLocale: locale,
    };

    try {
      const data = (await apiRequest(
        postGenerateGuarantorLink,
        'post',
        xLocale
      )) as Record<any, any>;

      if (data && data?.data && (data?.data?.guarantor_link as string)) {
        set({ guarantorLink: data.data.guarantor_link, status: 'success' });
        return;
      }
    } catch (error) {
      toast.error('Could not generate guarantor link, please try again!');
      set({ status: 'error' });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>).catch(
        (error) => console.error(error)
      );
    }
  },
  verifyBank: async (values: { accountNumber: string; bankCode: string }) => {
    set({ loading: true });
    try {
      const { data } = await axiosInstance.post(postVerifyBankAccount, {
        ...values,
      });

      set({ loading: false });

      return data.data;
    } catch (error) {
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
      set({ loading: false });
    }
  },
  setSelectedMandate(mandate: any) {
    set({ selectedMandate: mandate });
  },
  createMandate: async (values: {
    account_number: string;
    amount: number;
    bank_code: string;
    start_date: string;
    address: string;
  }) => {
    set({ loading: true });
    const { locale } = get();

    try {
      if (values.amount < 1000) {
        throw new Error(
          'Unable to create mandate for loan amount less than 1000'
        );
      }

      const response = await apiRequest(
        `${handleMandates}?suppress_notification=true`,
        'post',
        locale,
        values
      );

      set({ selectedMandate: (response as any).data });
    } catch (error) {
      console.log(error);
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    } finally {
      set({ loading: false });
    }
  },

  fetchMandates: async () => {
    // const { locale } = get();

    try {
      const response = (await apiRequest(handleMandates, 'get')) as any;

      set({ mandates: response.data });

      return response?.data;
    } catch (error) {
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },

  getBanks: async () => {
    const { locale } = get();
    try {
      const { data } = await axiosInstance.get(getAllBanks, {
        headers: { 'x-locale': `${locale}` },
      });

      return data.data.map((item: BankInfo) => {
        return {
          label: item.code_description,
          value: item.additional_code,
        };
      });
    } catch (error) {
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },
  addPhoto: async (values: FormData, type?: string) => {
    set({ loading: true });
    const { token, preSubmitForm, locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    const contentType = {
      ContentType: 'multipart/form-data',
    };

    try {
      const response = await axiosInstance.get(getPreferences, {
        headers: {
          authorization: `Bearer ${token}`,
          'x-locale': `${locale}`,
        },
      });

      const { data } = (await apiRequest(
        postUploadPhoto('irorun'),
        'post',
        { ...contentType, ...xLocale },
        values
      )) as AddPhoto;

      await apiRequest(addPhoto, 'post', xLocale, { photo_url: data.url });

      set({
        preferences: response.data.data,
        loading: false,
      });

      if (!type) {
        await preSubmitForm();
      }
    } catch (error) {
      set({
        loading: false,
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>).catch(
        (error) => console.error(error)
      );
    }
  },

  getLoanRepaymentBreakdown: async (data: any) => {
    const { xLocale, loan_data, bnpl_values } = get();

    set({ loanBreakdownStatus: 'loading' });

    try {
      const response = (await apiRequest(
        postLoanBreakdown,
        'post',
        xLocale,
        data
      )) as any;

      set({
        schedules: response.data.schedules,
        fees: response.data.fees,
        equityContribution: getFeeAmount(
          'Equity Contribution',
          response.data.fees,
          bnpl_values.requested_amount,
          loan_data.interest_rate
        ),
        loanBreakdownStatus: 'success',
      });
    } catch (e: any) {
      set({ loanBreakdownStatus: 'error' });
    }
  },

  initPayment: async (data: any) => {
    const { xLocale, bnpl_values } = get();

    set({ loading: true });

    try {
      if (data.password) {
        await apiRequest(createPin, 'post', xLocale, data);
      }

      (await apiRequest(paymentInit, 'post', xLocale, {
        pin: data?.pin,
        amount: bnpl_values?.requested_amount,
        description: 'temp description',
      })) as any;

      set({ loading: false });
    } catch (error: any) {
      set({ loading: false });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },

  completePayment: async (otp: string) => {
    const { xLocale, selectedCard } = get();

    set({ loading: true });

    try {
      (await apiRequest(paymentComplete, 'post', xLocale, {
        otp,
        card_id: selectedCard.id,
      })) as any;

      set({ loading: false });
    } catch (error: any) {
      set({ loading: false });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },

  selectLoan: async (loan: SelectedLoanInterface) => {
    set({ selectedLoan: loan, screen: screens.BREAKDOWN });
  },

  getWallet: async () => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    set({ loading: true });

    try {
      const response = await apiRequest(getWallet, 'get', xLocale)
        .then()
        .catch(console.error);
      set({ wallet: (response as any)?.data });
    } catch (error) {
      // await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    } finally {
      set({ loading: false });
    }
  },
  submitForm: async () => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };
    set({ loading: true });
    const { loan, longitude, latitude, user_certe_guid, logEvent, screen } =
      get();

    try {
      const propertiesToExclude = [
        'state',
        'city',
        'lga',
        'street',
        'phone_number',
      ];
      const loanWithoutExcludedProperties = Object.fromEntries(
        Object.entries(loan).filter(
          ([key]) => !propertiesToExclude.includes(key)
        )
      );

      apiRequest(getWallet, 'get', xLocale).then().catch(console.error);

      const response = (await apiRequest(postLoanEligibility, 'post', xLocale, {
        ...loanWithoutExcludedProperties,
        purpose: 'Purpose',
        latitude,
        longitude,
        ...(get().loan_data.reference !== null
          ? { reference: get().loan_data.reference }
          : {}),
      })) as LoanOffersResponse;

      const logEventDetails = {
        name: `Loan web SDK: Account details`,
        description: `User ${user_certe_guid} entered account details for depositing funds`,
      };

      logEvent(logEventDetails);

      set({
        loan_offers: response.data,
        loading: false,
        screen: screens.OFFERS,
      });
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null) {
        const errorObj = error as {
          message: string;
          data?: {
            current_loan?: boolean;
            payment_link?: string;
            failed_reason?: unknown;
          };
        };

        if (errorObj.message.includes('not eligible')) {
          const failed_reason =
            JSON.stringify(errorObj.data?.failed_reason) ||
            'Loan product not found';

          const logEventDetails = {
            name: `Loan web SDK: loan processing response`,
            description: `User ${user_certe_guid} was marked as ineligible for loan due to ${failed_reason}`,
          };

          logEvent(logEventDetails);

          set({
            loading: false,
            screen: screens.INELIGIBLE,
          });
        } else if (errorObj.message.includes('running loan')) {
          const logEventDetails = {
            name: `Loan web SDK: Repay Loan`,
            description: `User ${user_certe_guid} has a running or outstanding loan and is being redirected to repay it.`,
          };

          set({
            loading: false,
            runningLoan: errorObj.data?.payment_link,
            screen: screens.REPAYLOAN,
          });

          if (screen === screens.REPAYLOAN) {
            await handleAxiosError(
              errorObj as AxiosError<CustomErrorResponse>
            ).catch((error) => console.error(error));
          }

          logEvent(logEventDetails);
        } else {
          set({
            loading: false,
          });

          await handleAxiosError(
            error as AxiosError<CustomErrorResponse>
          ).catch((error) => console.error(error));
        }
      }
    }
  },

  preSubmitForm: async (page?: String) => {
    const { loanType } = get();
    set({
      loading: true,
    });

    await get().fetchDocs();

    const {
      // loan,
      documentTypes,
      documents,
      loan_data,
      // preferences,
      // latitude,
      // longitude,
      logEvent,
      // locale,
      user,
      user_certe_guid,
    } = get();

    // const xLocale = {
    //   XLocale: locale,
    // };

    const requiredDocumentTypes = documentTypes.filter(
      (doc: { id: number; required: number | string }) =>
        loan_data.documents.includes(doc.id) || doc.required
    );

    const newDoc = requiredDocumentTypes.map(
      (doc: { id: number; required: number | string }) => {
        return {
          ...doc,
          userData: documents.find(
            (userDoc: { type_id: number }) => userDoc.type_id === doc.id
          ),
        };
      }
    );

    const identity = newDoc.find(
      (doc: any) => doc.userData?.status.toLowerCase() === 'rejected'
    );

    const missingRequiredDocument = getMissingRequiredDocument(
      loan_data,
      newDoc
    );

    if (
      identity &&
      (!identity.userData ||
        identity.userData.status.toLowerCase() === 'rejected')
    ) {
      const userDocId = documents.find(
        (userDoc: { type_id: number }) => userDoc.type_id === identity.id
      )?.id;

      const modifiedString = missingRequiredDocument.header?.replace(
        'Upload your ',
        ''
      );

      const logEventDetails = {
        name: `${
          user.photo_url
            ? 'Loan web SDK: Document Upload'
            : 'Loan web SDK: Selfie Upload'
        }`,
        description: `${
          user.photo_url
            ? `User ${user_certe_guid} uploaded ${modifiedString}`
            : ` User ${user_certe_guid} verified uploaded selfie`
        }`,
      };
      set({
        missingDoc: {
          doc: identity,
          userDocId,
        },
        loading: false,
      });

      logEvent(logEventDetails);
      get().setScreen(screens.FILE_UPLOAD);

      if (page) {
        get().next(page, ScreenState.DOCUMENTS);
      } else if (get().bnpl_screen !== ScreenState.DOCUMENTS) {
        get().setBNPLScreen(ScreenState.DOCUMENTS);
      }

      return;
    } else if (missingRequiredDocument.missing) {
      set({
        missingDoc: {
          instructionHeader: missingRequiredDocument.header,
          doc: missingRequiredDocument.doc,
          userDocId: documents.find(
            async (userDoc: { type_id: number }) =>
              userDoc.type_id === missingRequiredDocument.doc?.id
          )?.id,
        },
        loading: false,
      });

      get().setScreen(screens.FILE_UPLOAD);

      if (page) {
        get().next(page, ScreenState.DOCUMENTS);
      } else if (get().bnpl_screen !== ScreenState.DOCUMENTS) {
        get().setBNPLScreen(ScreenState.DOCUMENTS);
      }
      return;
    }

    if (loanType === 'pay_later' && page) {
      get().next(page, ScreenState.REVIEW_TERMS);
    } else if (loanType === 'pay_now' && page) {
      get().next(page, ScreenState.PAYMENT);
    } else {
      get().setBNPLScreen(ScreenState.PAYMENT);
    }

    set({ loading: false });
  },
  fetchCards: async (reason = 'loading') => {
    const { locale } = get();

    set({
      loading: true,
    });

    const xLocale = {
      XLocale: locale,
    };

    try {
      set({ cardsStatus: reason });

      const response = (await apiRequest(
        getAllUserCards,
        'get',
        xLocale
      )) as UserCardsResponse;

      set({ cardsStatus: 'success', cards: response.data, loading: false });
    } catch (e: any) {
      if (typeof e === 'object' && e !== null) {
        const errorObj = e as { message: string };
        if (errorObj.message.includes('404')) {
          set({ cardsStatus: 'empty', loading: false });
        } else {
          set({ cardsStatus: 'error', loading: false });
        }
      }
    }
  },
  setSelectedCard: (card: any) => {
    set({ selectedCard: card });
  },
  setCardToUse: async (card_id: number) => {
    const { selectedLoan, user_certe_guid, logEvent } = get();

    const logEventDetails = {
      name: `Loan web SDK: Card details`,
      description: `User ${user_certe_guid} has added card for loan repayment`,
    };

    logEvent(logEventDetails);

    const backup_id = Number(sessionStorage.getItem('card_id') ?? '');

    set({
      card_id: backup_id ?? card_id,
      screen: selectedLoan.require_guarantor
        ? screens.GUARANTOR
        : screens.PASSWORD,
    });
  },
  setGuarantor: async (guarantor_id: number) => {
    set({
      guarantor_id,
      screen: screens.PASSWORD,
    });
  },
  getInitData: async () => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    try {
      set({ cardInitStatus: 'loading' });
      const response = (await apiRequest(
        postInitCard,
        'post',
        xLocale
      )) as CardsInitDataInterface;
      const { reference, ...params } = response.data;
      set({
        cardInitData: { ...params, ref: reference },
        loading: false,
      });
    } catch (error) {
      set({
        loading: false,
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>).catch(
        (error) => console.error(error)
      );
    }
  },
  fetchUserGuarantors: async () => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    set({ guarantorsStatus: 'loading' });
    try {
      const response = (await apiRequest(
        getUserGuarantors,
        'get',
        xLocale
      )) as GuarantorsInterface;
      set({ guarantorsStatus: 'success' });
      set({ guarantors: response.data });
    } catch (e) {
      set({ guarantorsStatus: 'error' });
    }
  },
  checkScore: async () => {
    const { bnpl_values, loan_data, locale, latitude, longitude, attributes } =
      get();
    const xLocale = {
      XLocale: locale,
    };

    set({ loading: true });
    try {
      let payload = {};

      if (!loan_data.product.attributes.show_only_clf) {
        payload = {
          educational_attainment: bnpl_values.educational_attainment ?? '',
          employment_status: bnpl_values.employment_status ?? '',
          gender: bnpl_values.gender ?? '',
          marital_status: bnpl_values.marital_status ?? '',

          monthly_net_income: bnpl_values.monthly_net_income ?? '-',
          no_of_dependent: bnpl_values.no_of_dependent ?? '',
          sector_of_employment: bnpl_values.sector_of_employment ?? '',
          type_of_residence: bnpl_values.type_of_residence ?? '',
          work_email: bnpl_values.work_email ?? '-',
          work_start_date: bnpl_values.work_start_date ?? 0,
        };
      }

      const response = (await apiRequest(postLoanEligibility, 'post', xLocale, {
        latitude,
        longitude,
        ...(get().loan_data.reference !== null
          ? { reference: get().loan_data.reference }
          : {}),
        product_id: loan_data?.product?.id,
        attributes: attributes,
        proposed_payday: bnpl_values?.proposed_payday,
        proposed_tenor: bnpl_values?.proposed_tenor,
        purpose: 'Purpose',
        requested_amount: bnpl_values?.requested_amount,
        ...payload,
      })) as any;

      set({ loading: false, selectedLoan: response?.data[0] });
    } catch (error) {
      set({
        loading: false,
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },
  getOffer: async () => {
    const { bnpl_values, loan_data, locale, latitude, longitude, attributes } =
      get();
    const xLocale = {
      XLocale: locale,
    };

    set({ loading: true });
    try {
      await apiRequest(postCheckLoanEligibility, 'post', xLocale, {
        latitude,
        longitude,
        ...(get().loan_data.reference !== null
          ? { reference: get().loan_data.reference }
          : {}),
        product_id: loan_data?.product?.id,
        attributes: attributes,
        proposed_payday: bnpl_values?.proposed_payday,
        proposed_tenor: bnpl_values?.proposed_tenor,
        purpose: 'Purpose',
        requested_amount: bnpl_values?.requested_amount,
      });

      set({ loading: false });
    } catch (error) {
      set({
        loading: false,
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },
  createLoan: async (values: { password: string; pin: string }) => {
    const { loan_data, next } = get();

    set({ loading: true });
    const {
      selectedLoan,
      card_id,
      guarantor_id,
      locale,
      selectedCard,
      selectedMandate,
    } = get();
    const xLocale = {
      XLocale: locale,
    };

    try {
      if (values.password) {
        await apiRequest(createPin, 'post', xLocale, values);
      }

      const body = {
        token: selectedLoan?.loan_token,
        pin: values.pin,
        channel: 'web',
        guarantor_id: guarantor_id || undefined,
      } as any;

      if (selectedMandate) {
        body.mandate_id = selectedMandate.id;
      }

      if (selectedCard) {
        body.card_id = card_id ?? selectedCard?.id;
      }

      const loan = (await apiRequest(
        postCreateLoan,
        'post',
        xLocale,
        body
      )) as any;

      set({
        loading: false,
        screen: screens.SUCCESS,
        message: loan.message,
      });

      if (loan_data.product.attributes.require_guarantor) {
        next(ScreenState.PIN, ScreenState.GUARANTOR);
        return;
      }

      get().next(ScreenState.PIN, ScreenState.CONFIRMATION);
    } catch (error: any) {
      set({
        loading: false,
        code: error?.response?.data?.code,
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>).catch(
        (error) => console.error(error)
      );
    }
  },
  fetchUserDocs: async (reason = 'loading') => {
    const { documents, locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    let status = reason;
    if (documents.length > 0) {
      status = 'updating';
    }
    set({ userDocStatus: status });
    try {
      const response = (await apiRequest(
        getUserDocuments,
        'get',
        xLocale
      )) as DocumentsInterface;
      set({ userDocStatus: 'success' });
      set({ documents: response.data });
    } catch (e) {
      set({ userDocStatus: 'error', status: 'failed' });
    }
  },
  fetchDocumentTypes: async () => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    set({ docStatus: 'loading' });
    try {
      const response = (await apiRequest(
        getDocumentTypes,
        'get',
        xLocale
      )) as DocumentTypes;
      set({ docStatus: 'success' });
      set({ documentTypes: response.data.documents });
      return response.data.documents;
    } catch (e) {
      set({ docStatus: 'error', status: 'failed' });
    }
  },
  fetchDocs: async () => {
    const store = get();
    await store.fetchDocumentTypes();
    await store.fetchUserDocs();
  },
  scrollToTop: async (refObject: RefObject<HTMLDivElement> | null) => {
    if (refObject?.current) {
      refObject.current.scrollTop = 0;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  translate: (value: string, args: any) => {
    try {
      return translate(`${value}`, {
        ...args,
      });
    } catch (err) {
      console.error(err);
    }
  },
  reset: () => {
    set(defaultState);
  },
});

const storage = {
  getItem: async (name: string) => {
    return sessionStorage.getItem(name);
  },
  setItem: async (name: string, value: string) => {
    const data = JSON.parse(value);
    sessionStorage.setItem(
      name,
      JSON.stringify({
        ...data,
        state: { ...data.state, loading: false },
      })
    );
  },
};

const useApplicationStore = create<InvestorStoreInterface>(
  persist(InvestorStore, {
    name: 'application',
    getStorage: () => storage,
  })
);

export default useApplicationStore;

export const handleAxiosError = (
  error: AxiosError<CustomErrorResponse>
): Promise<string> => {
  if (axios.isAxiosError(error)) {
    const responseMessage = error.response?.data?.message;

    if (responseMessage) {
      toast.error(responseMessage);
      return Promise.reject(new Error(responseMessage));
    }
  }

  if (error.message) {
    toast.error(error.message);
    return Promise.reject(new Error(error.message));
  }

  // Handle the case where neither error.isAxiosError nor error.message exists
  // Return a generic error message or handle the case accordingly
  toast.error('An error occurred.');
  return Promise.reject(new Error('An error occurred.'));
};

const inputSlashDates = (date: moment.MomentInput) =>
  moment(date).format('MM/DD/YYYY');
