import { Textfield } from '../../base/Textfield';
import { LoanData } from '../../../utils/interfaces';
import useApplicationStore from '../../../state';

import { DateField } from '../../base/DateField';
import { Spacer } from '../../base/Spacer';
import { PhoneField } from '../../base/PhoneField';
import { Tenor } from '../../base/Tenor';

type AmountSelectionProp = {
  minDate?: Date;
  maxDate?: Date;
};

function AmountSelection({ minDate, maxDate }: AmountSelectionProp) {
  const [loan_data, country_code, bnpl_values] = useApplicationStore(
    (state) => [
      state.loan_data as LoanData,
      state.country_code,
      state.bnpl_values,
    ]
  );

  return (
    <div>
      <Spacer height={20} />

      <Textfield
        label={`Amount (${loan_data?.currency.toUpperCase() ?? 'NGN'})`}
        name="requested_amount"
        type="number"
      />

      <Spacer height={32} />

      <Tenor />

      <Spacer height={32} />

      <DateField
        minDate={minDate}
        maxDate={maxDate}
        name={'proposed_payday'}
        initialDate={minDate}
      />

      <Spacer height={32} />

      <PhoneField
        country_code={country_code}
        value={bnpl_values?.phone_number || loan_data?.phone_number || ''}
        name="phone_number"
        label="Phone Number"
      />
    </div>
  );
}

export { AmountSelection };
