import { useField } from 'formik';
import { useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

type PhoneField = {
  country_code: string;
  value: string;
  label?: string;
  name?: string;
};

function PhoneField({ country_code, value, label, name }: PhoneField) {
  const [, meta, helpers] = useField(name ?? '');

  const handleTelChange = useCallback(
    (e: any) => {
      helpers.setValue(`+${e}`);
      helpers.setTouched(true);
    },
    [helpers, confirm, name]
  );

  return (
    <div className="input-holder-holder">
      <div className="input-holder-phone">
        <PhoneInput
          country={country_code}
          value={value}
          containerClass=""
          inputClass={`phoneInput `}
          enableAreaCodes={true}
          onlyCountries={['ng', 'rw', 'fr']}
          countryCodeEditable={false}
          placeholder="Phone number"
          onChange={handleTelChange}
          onBlur={() => helpers.setTouched(true)}
        />

        <label>{label}</label>
      </div>

      {meta.touched && meta.error && (
        <small className="error">
          {meta.touched && !!meta.error && meta.error}
        </small>
      )}
    </div>
  );
}

export { PhoneField };
