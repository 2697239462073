import { useCallback, useEffect, useState } from 'react';
import { ScreenState } from '../bnpl';
import { BNPLBody } from '../layout/BNPLBody';
import { BlueAmountSummaryCard } from '../../base/BlueAmountSummaryCard';
import { Spacer } from '../../base/Spacer';
import { Footer } from '../Footer';
import { CheckboxField } from '../../base/CheckboxField';
import useApplicationStore from '../../../state';
import { LoanData } from '../../../utils/interfaces';
import {
  getLoanAmountFromSchedule,
  money,
  repaymentDates,
} from '../../../utils/formatter';

function ReviewTerms() {
  const [
    bnpl_values,
    loan_data,
    getLoanRepaymentBreakdown,
    checkScore,
    loading,
    schedules,
    bnpl_conf,
    equityContribution,
    next,
    back,
  ] = useApplicationStore((state) => [
    state.bnpl_values,
    state.loan_data as LoanData,
    state.getLoanRepaymentBreakdown,
    state.checkScore,
    state.loading,
    state.schedules,
    state.bnpl_conf,
    state.equityContribution,
    state.next,
    state.back,
  ]);

  const [isAware, setIsAware] = useState(false);
  const [isEquity, setIsEquity] = useState(false);

  const onBack = useCallback(() => {
    back();
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      await checkScore();
      next(ScreenState.REVIEW_TERMS, ScreenState.PAYMENT);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const getSchedule = async () => {
      await getLoanRepaymentBreakdown({
        principal: bnpl_values.requested_amount,
        product_id: loan_data?.product?.id,
        start_date: bnpl_values?.proposed_payday,
        tenor_period: bnpl_values?.proposed_tenor_period,
        tenor: bnpl_values.proposed_tenor,
      });
    };

    getSchedule();
  }, []);

  const normalSummary = `You will repay ${money(
    getLoanAmountFromSchedule(schedules),
    loan_data?.currency
  )} by 
                        ${repaymentDates(
                          schedules
                            ? schedules[schedules?.length - 1]?.due_date
                            : ''
                        )} in ${bnpl_values?.proposed_tenor} instalment(s).`;

  const summaryWithEquityContribution = `and an ${
    bnpl_conf?.equity_contribution?.label ?? 'equity contribution'
  }  of ${money(equityContribution, loan_data?.currency)} now.`;

  return (
    <>
      <BNPLBody
        title={
          bnpl_conf?.terms_review?.description ??
          'Kindly review your offer and accept the terms to proceed.'
        }
      >
        <Spacer height={20} />
        <BlueAmountSummaryCard
          amount={bnpl_values.requested_amount}
          currency="ngn"
          // tenorText="For 10 months at 10% APR"
          tenorText={
            bnpl_conf?.interest_rate?.enabled
              ? `For ${bnpl_values?.proposed_tenor} 
                        ${
                          bnpl_values?.proposed_tenor === 1
                            ? bnpl_values?.proposed_tenor_period?.replace(
                                's',
                                ''
                              )
                            : bnpl_values?.proposed_tenor_period
                        }
                        at ${loan_data?.interest_rate}% ${
                          bnpl_conf?.interest_rate?.label ?? 'Interest'
                        }`
              : ''
          }
          summary={
            equityContribution
              ? normalSummary + summaryWithEquityContribution
              : normalSummary
          }
        />

        <Spacer height={20} />

        <CheckboxField
          name="term-aware"
          id="term-aware"
          value="term-aware"
          isChecked={isAware}
          handleChange={() => setIsAware(!isAware)}
          label="I accept the total costs associated with this service"
        />

        <Spacer height={16} />

        <CheckboxField
          name="term-aware"
          id="term-aware-2"
          value="term-aware"
          isChecked={isEquity}
          handleChange={() => setIsEquity(!isEquity)}
          label="I accept the terms and conditions associated with this service"
        />
      </BNPLBody>

      <Footer
        loading={loading}
        disabled={!isAware || !isEquity}
        onBack={onBack}
        onNext={onSubmit}
      />
    </>
  );
}

export { ReviewTerms };
