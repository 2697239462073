import * as Yup from 'yup';
import { ScreenState } from '../bnpl';
import { useCallback, useMemo, useState } from 'react';
import { FormLayout } from '../layout/FormLayout';
import useApplicationStore from '../../../state';
import { useTranslation } from '@lendsqr/lingua-react';
import { PhoneField } from '../../base/PhoneField';
import { Spacer } from '../../base/Spacer';
import { Textfield } from '../../base/Textfield';
import { PasswordField } from '../../base/PasswordField';
import usePasswordValidation from '../../../hooks/usePasswordValidation';

function AccountInformation() {
  const { translate } = useTranslation();

  const { validate } = usePasswordValidation();

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [country_code, bnpl_conf, next, back] = useApplicationStore((state) => [
    state.country_code,
    state.bnpl_conf,
    state.next,
    state.back,
  ]);

  const onBack = useCallback(() => {
    back();
  }, []);

  const [user_activated, bnpl_values, login, setBNPLValues, loading] =
    useApplicationStore((state) => [
      state.user_activated,
      state.bnpl_values,
      state.login,
      state.setBNPLValues,
      state.loading,
    ]);

  const validation = useMemo(() => {
    const loginSchema = Yup.object({
      phone_number: Yup.string()
        .min(6, translate('lender-web-app-phone-min'))
        .max(20, translate('lender-web-app-phone-max'))
        .required(translate('lender-web-app-phone-number-error')),
      password: Yup.string().required('Please enter a valid password'),
    });

    const signUpSchema = Yup.object({
      repeat_password: Yup.string().required('Please enter a valid password'),
      email_address: Yup.string()
        .email()
        .required('Please enter a valid email'),
    });

    if (user_activated) {
      return loginSchema;
    }

    return loginSchema.concat(signUpSchema);
  }, [user_activated]);

  const initialValues = useMemo(() => {
    return { ...bnpl_values };
  }, []);

  const onSubmit = useCallback(
    async (values: any) => {
      if (user_activated) {
        login({ ...values, device_id: 'web' });
      } else {
        setBNPLValues(values);
        next(ScreenState.ACCOUNT_INFORMATION, ScreenState.BVN_VALIDATION);
      }
    },
    [user_activated]
  );

  console.log(bnpl_values.phone_number);

  return (
    <>
      {validation && (
        <FormLayout
          initialValues={initialValues}
          onSubmit={onSubmit}
          onBack={onBack}
          validationSchema={validation}
          title={
            bnpl_conf?.account_information?.description ??
            (user_activated
              ? 'Welcome back, enter your details to login'
              : 'This information will be used to create your account.')
          }
          loading={loading}
        >
          <Spacer height={32} />

          <PhoneField
            country_code={country_code}
            value={bnpl_values?.phone_number}
            name={bnpl_values?.phone_number}
            label="Phone Number"
          />

          <Spacer height={32} />

          {user_activated && (
            <PasswordField
              password={password}
              setPassword={setPassword}
              validate={validate}
              name="password"
              label={translate('lender-web-app-password')}
            />
          )}

          {!user_activated && (
            <>
              <Textfield
                type="text"
                label="Email address"
                name="email_address"
              />

              <Spacer height={40} />

              <p className="sm-regular">Create your secure password.</p>

              <Spacer height={20} />

              <PasswordField
                password={password}
                setPassword={setPassword}
                validate={validate}
                name="password"
                label={translate('lender-web-app-password')}
                showHint
              />

              <Spacer height={32} />

              <PasswordField
                password={repeatPassword}
                setPassword={setRepeatPassword}
                validate={validate}
                name="repeat_password"
                label="Repeat password"
                match={password}
              />
            </>
          )}
        </FormLayout>
      )}
    </>
  );
}

export { AccountInformation };
