import { useEffect, useState } from 'react';
import useApplicationStore from '../../../state';
import { LoanData } from '../../../utils/interfaces';
import { ChoosePayment } from './ChoosePayment';
import { BNPLCards } from './cards/BNPLCards';
import { Mandate } from './create-mandate/Mandate';
import { ScreenState } from '../bnpl';

function PaymentMethods() {
  const [loan_data, back, next, bnpl_conf, loanType] = useApplicationStore(
    (state) => [
      state.loan_data as LoanData,
      state.back,
      state.next,
      state.bnpl_conf,
      state.loanType,
    ]
  );

  const [method, setMethod] = useState('cards');

  useEffect(() => {
    if (
      bnpl_conf?.bnpl &&
      bnpl_conf?.bnpl[loanType] &&
      bnpl_conf?.bnpl[loanType]?.payment_method
    ) {
      setMethod(bnpl_conf?.bnpl[loanType]?.payment_method);
      return;
    }

    if (
      loan_data.product.attributes['repayment-methods'][
        'direct-debit'
      ] as Boolean
    ) {
      setMethod('bank');
    } else if (
      loan_data.product.attributes['repayment-methods']['debit-card'] as Boolean
    ) {
      setMethod('card');
    } else {
      setMethod('optional');
    }
  }, [loanType]);

  const onBack = () => {
    if (
      bnpl_conf?.bnpl &&
      bnpl_conf?.bnpl[loanType] &&
      bnpl_conf?.bnpl[loanType]?.payment_method
    ) {
      back();
      return;
    }
    if (
      method === 'card' &&
      (loan_data.product.attributes['repayment-methods'][
        'direct-debit'
      ] as Boolean)
    ) {
      setMethod('bank');
      return;
    }

    back();
  };

  const onNext = () => {
    if (
      method === 'bank' &&
      (loan_data.product.attributes['repayment-methods'][
        'debit-card'
      ] as Boolean)
    ) {
      setMethod('card');
      return;
    }

    next(ScreenState.PAYMENT_METHODS, ScreenState.PIN);
  };

  return (
    <>
      {method === 'optional' && (
        <ChoosePayment setMethod={(value: string) => setMethod(value)} />
      )}

      {method === 'card' && <BNPLCards onBack={onBack} onNext={onNext} />}

      {method === 'bank' && <Mandate onBack={onBack} onNext={onNext} />}
    </>
  );
}

export { PaymentMethods };
